<template>
  <div>
    <b-tabs nav-wrapper-class="faq-navigation" nav-class="nav-left">
      <b-tab>
        <template #title>
          <span>Müşteri Bilgileri</span>
        </template>
        <card-customer-info
          :customer="cargo.cargo.customer"
        ></card-customer-info>
      </b-tab>
      <b-tab>
        <template #title class="bg-primary">
          <span>Takip Formu</span>
        </template>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Kargo Takibi #{{ cargo.cargo.c_id }}
                </div>
                <span v-if="cargo.cargo.source == 1" class="badge badge-warning"
                  >Müşteri Gönderisi</span
                >
              </div>
              <div class="card-body p-0">
                <div class="border-top border-bottom p-1">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <small class="text-sm text-uppercase font-semibold"
                          >Yön</small
                        >
                        <v-select
                          v-model="cargo.cargo.type"
                          :options="[
                            { label: 'Müşteriden Gelen Kargo', value: 'take' },
                            { label: 'Müşteriye Giden Kargo', value: 'send' },
                          ]"
                          :reduce="(option) => option.value"
                          :searchable="false"
                          :clearable="false"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-4" v-if="cargo.cargo.source == 0">
                      <div class="form-group">
                        <small class="text-sm text-uppercase font-semibold"
                          >Planlanan Tarih</small
                        >
                        <flat-pickr
                          class="form-control"
                          v-model="cargo.cargo.planned_date"
                          :config="{
                            enableTime: false,
                            altFormat: 'd.m.Y',
                            altInput: true,
                            dateFormat: 'Y-m-d',
                            locale: 'tr',
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <small class="text-sm text-uppercase font-semibold"
                          >Gerçekleşen Tarih</small
                        >
                        <flat-pickr
                          class="form-control"
                          v-model="cargo.cargo.complated_date"
                          :disabled="cargo.cargo.source == 1"
                          :config="{
                            enableTime: false,
                            altFormat: 'd.m.Y',
                            altInput: true,
                            dateFormat: 'Y-m-d',
                            locale: 'tr',
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="form-group">
                        <small class="font-medium text-uppercase"
                          >Kargo Firması</small
                        >
                        <v-select
                          v-model="cargo.cargo.cargo_company_id"
                          :searchable="false"
                          :clearable="false"
                          :options="global.cargo_companies"
                          :reduce="(curr) => curr.value"
                          :disabled="cargo.cargo.source == 1"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <small class="font-medium text-uppercase text-primary"
                          >Kargo Takip Kodu</small
                        >
                        <input
                          :disabled="cargo.cargo.source == 1"
                          v-model="cargo.cargo.cargo_tracking_code"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="font-medium text-uppercase"
                          >Karşı Ödemeli</label
                        >
                        <v-select
                          v-model="cargo.cargo.customer_will_pay"
                          :reduce="(curr) => curr.value"
                          :searchable="false"
                          :clearable="false"
                          :options="global.yesno"
                          label="label"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="font-medium text-uppercase"
                          >Kargo Bedeli</label
                        >
                        <input
                          v-model="cargo.cargo.cargo_price"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="font-medium text-uppercase">Ofis</label>
                    <v-select
                      v-model="cargo.cargo.target_office_id"
                      :searchable="true"
                      :clearable="false"
                      :options="global.offices"
                      :reduce="(curr) => curr.value"
                    />
                  </div>

                  <b-row>
                    <b-col md="6" lg="6">
                      <div class="form-group">
                        <label class="font-medium text-uppercase">İl</label>
                        <v-select
                          :searchable="true"
                          :clearable="false"
                          :options="global.cities"
                          :reduce="(curr) => curr.value"
                          :disabled="cargo.cargo.source == 1"
                          label="label"
                          v-on:input="changeCity()"
                          v-model="cargo.cargo.city_id"
                        />
                      </div>
                    </b-col>
                    <b-col md="6" lg="6">
                      <div class="form-group">
                        <label class="font-medium text-uppercase">İlçe</label>
                        <v-select
                          :searchable="true"
                          :clearable="true"
                          :reduce="(curr) => curr.district_id"
                          :options="district_items"
                          :disabled="cargo.cargo.source == 1"
                          label="label"
                          v-model="cargo.cargo.district_id"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Açık Adres</small
                    >
                    <textarea
                      :disabled="cargo.cargo.source == 1"
                      v-model="cargo.cargo.address"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </div>
                  <div class="form-group" v-if="cargo.cargo.source == 1">
                    <small class="text-sm text-uppercase font-semibold"
                      >Müşteri Notları</small
                    >
                    <textarea
                      disabled
                      v-model="cargo.cargo.notes_customer"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Kargo Notları</small
                    >
                    <textarea
                      v-model="cargo.cargo.notes"
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Durum</small
                    >
                    <v-select
                      v-model="cargo.cargo.status"
                      :options="[
                        { label: 'Beklemede', value: 'pending' },
                        { label: 'Tamamlandı', value: 'complated' },
                      ]"
                      :reduce="(option) => option.value"
                      :searchable="false"
                      :clearable="false"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <button class="btn btn-primary" @click="saveCargoEntity()">
                      Kaydet
                    </button>
                    <button
                      v-if="btn_show_create_service_form"
                      @click="cargoAccept()"
                      class="btn btn-outline-primary ml-1"
                    >
                      Servis Formu Oluştur
                    </button>
                    <button
                      v-if="btn_show_cargo_accept_form"
                      @click="sendSMSCargoAcceptFromOffice()"
                      class="btn btn-outline-primary ml-1"
                    >
                      Teslim Alındı Bilgisi Gönder
                    </button>
                    <router-link
                      v-if="cargo.sf != null"
                      :to="{
                        name: 'service',
                        query: { uniq: cargo.sf.sf_uniq },
                      }"
                    >
                      <button class="btn btn-link">
                        Servis Formunu Görüntüle
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title class="bg-primary">
          <span>Paket İçeriği</span>
        </template>

        <div class="card">
          <div class="card-header">
            <div class="card-title">Kargo Paketi</div>
            <div>
              <button
                v-on:click="datasource.packet_show_to = 'list'"
                class="btn btn-sm"
                :class="{ 'btn-primary': datasource.packet_show_to == 'list' }"
              >
                Görüntüle
              </button>
              <button
                v-on:click="datasource.packet_show_to = 'add'"
                class="btn btn-sm"
                :class="{ 'btn-primary': datasource.packet_show_to == 'add' }"
              >
                Ekle
              </button>
            </div>
          </div>
          <div class="card-body pt-0 pb-0 mt-0 border-top">
            <div v-if="datasource.packet_show_to == 'add'" class="mt-1">
              <b-form-radio-group
                v-model="datasource.packet_type"
                :options="[
                  { label: 'Cihaz', value: 'device' },
                  { label: 'Diğer', value: 'other' },
                ]"
                class=""
                value-field="value"
                text-field="label"
              />
              <div>
                <div
                  v-if="datasource.packet_type == 'device'"
                  class="form-group pt-1 pb-1"
                >
                  <small class="font-weight-bold text-uppercase"
                    >Cihaz No</small
                  >
                  <div class="input-group">
                    <input
                      v-model="datasource.device_id"
                      type="text"
                      class="form-control"
                      placeholder="cihaz no ile doğrudan ekleme yapın"
                    />
                    <div class="input-group-append">
                      <button
                        v-on:click="addCargoItem('', 'device')"
                        class="btn btn-outline-secondary"
                        type="button"
                      >
                        Kaydet
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  v-if="datasource.packet_type == 'other'"
                  class="form-group pt-1 pb-1"
                >
                  <small class="font-weight-bold text-uppercase"
                    >Nesne Ekle</small
                  >
                  <div class="input-group">
                    <input
                      v-model="datasource.cargo_item_name"
                      type="text"
                      class="form-control"
                      placeholder="kargo paketine eklediğiniz diğer nesneleri yazın"
                    />
                    <div class="input-group-append">
                      <button
                        v-on:click="addCargoItem('', 'other')"
                        class="btn btn-outline-secondary"
                        type="button"
                      >
                        Kaydet
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="datasource.packet_type == 'device'"
                  class="ml-n2 mr-n2 mt-2"
                >
                  <b-table
                    ref="table_customer_devices"
                    responsive
                    striped
                    small
                    hover
                    :items="customer_devices"
                    :fields="datasource.table_customer_devices.fields"
                    class="mb-0 align-top"
                    show-empty
                  >
                    <template #cell(task_type)="data">
                      <div class="font-weight-bold">
                        <div>{{ data.item.task_type_text }}</div>
                        <small class="font-weight-bold">{{
                          data.item.priority_text
                        }}</small>
                      </div>
                    </template>
                    <template #cell(task_definition)="data">
                      <div class="font-weight-bold">
                        {{ data.value }}
                      </div>
                    </template>
                    <template #cell(device_item)="data">
                      <div class="font-weight-bold">
                        {{ data.value.device_name }}
                      </div>
                    </template>
                    <template #cell(date_item)="data">
                      <div>
                        {{ data.value }}
                      </div>
                    </template>
                    <template #cell(action_item)="data">
                      <button
                        class="btn btn-sm btn-primary"
                        v-on:click="addCargoItem(data.item, 'device')"
                      >
                        Ekle
                      </button>
                    </template>
                    <template #empty>
                      <div class="pt-3 pb-3 text-center">
                        Listelenecek bir kayıt bulunmuyor.
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div v-if="datasource.packet_show_to == 'list'" class="ml-n2 mr-n2">
              <b-table
                ref="table_cargo_items"
                class="mb-0 align-top"
                show-empty
                responsive
                striped
                hover
                selectable
                select-mode="single"
                :items="cargo_items"
                :fields="datasource.table_cargo_items.fields"
                :tbody-tr-class="rowClass"
                @row-selected="setCargoItem"
              >
                <template #cell(type_text)="data">
                  <div>
                    {{ data.value }}
                  </div>
                </template>
                <template #cell(item_name)="data">
                  <div>
                    {{ data.value }}
                  </div>
                </template>
                <template #cell(device_id)="data">
                  <div>
                    <span v-if="data.value == 0">Hayır</span>
                    <span v-if="data.value > 0">Evet</span>
                  </div>
                </template>

                <template #empty>
                  <div class="pt-3 pb-3 text-center">
                    Listelenecek bir kayıt bulunmuyor.
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="card" v-if="datasource.cargo_detail_item != null">
          <div class="card-header">
            <div class="card-title">Cihaz Bilgileri</div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Tür</label>
                  <input
                    :value="datasource.cargo_detail_item.type_text"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Belirtilen Marka</label>
                  <input
                    :value="datasource.cargo_detail_item.brand_text"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Belirtilen Model</label>
                  <input
                    :value="datasource.cargo_detail_item.model_name"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Belirtilen Seri No</label>
                  <input
                    :value="datasource.cargo_detail_item.serial_number"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Sistem Parolası</label>
                  <input
                    :value="datasource.cargo_detail_item.password_system"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Bios Parolası</label>
                  <input
                    :value="datasource.cargo_detail_item.password_bios"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Bios Parolası</label>
                  <textarea
                    class="form-control"
                    disabled
                    rows="5"
                    :value="datasource.cargo_detail_item.customer_device_notes"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-title">Yüklemeler</div>
            <div>
              <b-row>
                <b-col
                  lg="4"
                  sm="6"
                  class="p-1"
                  v-for="file in datasource.cargo_detail_item.files"
                  :key="file.fid"
                >
                  <div>
                    <b-img thumbnail fluid :src="file.fullurl" />
                    <div
                      class="
                        mt-1
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    ></div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title class="bg-primary">
          <span>Bildirimler </span>
        </template>
        <div class="card">
          <div class="card-header">
            <div class="card-title">Bildirimler</div>
            <button
              @click="showModalNotification()"
              class="btn btn-sm btn-primary"
            >
              Yeni
            </button>
          </div>
          <div class="card-body p-0">
            <b-table
              ref="table_notifications"
              responsive
              striped
              hover
              :items="sms_notifications"
              :fields="datasource.table_notifications.fields"
              class="mb-0 align-top"
              show-empty
            >
              <template #cell(log_text_1)="data">
                <div>
                  {{ data.value }}
                </div>
              </template>
              <template #cell(date_item)="data">
                <div
                  v-if="moment(data.item.created_props.created_at).isValid()"
                  class="font-weight-bold"
                >
                  {{
                    moment(data.item.created_props.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    )
                  }}
                </div>
              </template>
              <template #empty>
                <div class="pt-3 pb-3 text-center">
                  Listelenecek bir kayıt bulunmuyor.
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <send-sms-notification
      :customer="cargo.cargo.customer"
      entity="cargo"
      :entityuniq="cargo.cargo.c_uniq"
    ></send-sms-notification>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import CardCustomerSearch from "@/views/common/CardCustomerSearch";
import CardCustomerInfo from "@/views/common/CardCustomerInfo";
import SendSmsNotification from "@/views/common/SendSmsNotification.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    vSelect,
    flatPickr,
    CardCustomerSearch,
    CardCustomerInfo,
    SendSmsNotification,
  },
  data() {
    return {
      datasource: {
        device_id: 0,
        show_button_create_service_form: false,
        cargo_detail_item: null,
        packet_type: "device",
        cargo_item_name: "",
        packet_show_to: "list",
        customer_devices: [],
        table_customer_devices: {
          fields: [
            {
              key: "device_id",
              label: "Cihaz No",
              sortable: true,
              selected: true,
            },
            {
              key: "device_name",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            { key: "date_item", label: "Tarih", sortable: true },
            { key: "action_item", label: "", sortable: true },
          ],
        },
        table_cargo_items: {
          fields: [
            {
              key: "type_text",
              label: "Tür",
              sortable: true,
              selected: true,
            },
            {
              key: "item_name",
              label: "Başlık",
              sortable: true,
              selected: true,
            },
            {
              key: "device_id",
              label: "Kayıt Edildi Mi",
              sortable: true,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            {
              key: "action_item",
              label: "",
              sortable: false,
              tdClass: "text-right",
              thClass: "text-right",
            },
          ],
          selected: null,
        },
        table_notifications: {
          fields: [
            {
              key: "log_attr_1",
              label: "Başlık",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: true,
              selected: true,
            },
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "saveCargo",
      "getCargoDetail",
      "saveCargoItem",
      "sendSMSCargoAcceptFromOffice",
      "cargoAccept",
    ]),
    emitCustomerDevices($emit) {
      this.datasource.customer_devices = $emit;
    },
    saveCargoEntity() {
      this.saveCargo().then((q) => {
        if (!q && !q.success) {
          return;
        }
        this.$router.replace({
          name: "cargotracking-detail",
          query: { uniq: q.data.c_uniq },
        });
      });
    },
    addCargoItem(item, type) {
      let item_name = "";
      if (type == "device") {
        item_name = item.device_name;
        this.cargo.cargo_item.device_id = this.datasource.device_id;
      }
      if (type == "other") {
        this.cargo.cargo_item.item_name = this.datasource.cargo_item_name;
        this.cargo.cargo_item.type = type;
        this.cargo.cargo_item.ci_id = 0;
      }
      this.saveCargoItem();
    },
    removeCargoItem(item) {
      console.log("item", item);
    },
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    changeCity() {
      this.cargo.cargo.district_id = null;
      this.cargo.districts = [];
      this.getDistricts();
    },
    getDistricts() {
      let city_id = this.cargo.cargo.city_id;
      let items = utils.getDistricts(city_id, { viewtype: "vselect" });
      this.cargo.districts = items;
    },
    moment(v) {
      return moment(v);
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    setCargoItem(item) {
      this.datasource.cargo_detail_item = item[0] != null ? item[0] : null;
    },
    getCargoDetailItem() {
      let uniq = this.$route.query.uniq;
      if (!utils.isNullOrEmpty(uniq)) {
        this.getCargoDetail({ uniq: uniq });
      } else {
        this.$router.push("/not-found");
      }
    },
    moment(v) {
      return utils.moment(v);
    },
    showModalNotification() {
      this.$root.$emit("bv::toggle::modal", "modal_send_notification_sms");
    },
  },
  created() {
    this.getCargoDetailItem();
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
    cargo_items() {
      let result = [];
      this.cargo.cargo_items.forEach((q) => {
        result.push(q);
      });
      return result;
    },
    customer_devices() {
      let result = [];
      this.datasource.customer_devices.forEach((q) => {
        let item = {
          item: q,
          device_id: q.device_id,
          device_name: q.device_name,
          date_item: this.moment(q.created_props.created_at).format(
            "DD.MM.YYYY HH:mm"
          ),
        };
        result.push(item);
      });
      return result;
    },
    district_items() {
      let result = [];
      result = this.cargo.districts;
      result.forEach((q) => {
        if (!q.label) {
          q.label = q.district_name;
        }
      });
      return result;
    },
    sms_notifications() {
      let result = [];
      if (this.cargo && this.cargo.logs) {
        result = this.cargo.logs;
      }
      return result;
    },
    btn_show_cargo_accept_form() {
      let result = false;
      if (this.cargo == null || this.cargo.logs == null) return result;

      if (this.cargo.cargo.source == 1) {
        let d = this.cargo.logs.filter(
          (q) => q.log_type == "sms_cargo_accepted_from_office"
        );
        result = d.length > 0 ? false : true;
      }

      return result;
    },
    btn_show_create_service_form() {
      let result = false;
      //müşteri kargodan gönderince servis formu oluşturabilir
      if (this.cargo.cargo.sf_id == "0" && this.cargo.cargo.source == 1) {
        result = true;
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>